import {
  handleMiddlewareError,
  maybe_refresh_auth_token_middlware,
} from './utils';

export async function claimBonusPersisted(bonus, useAdBoost) {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.post('/api/bonus/claim', {
      id: bonus.id,
      useAdBoost,
    })
  ).catch(handleMiddlewareError);
  return data;
}

export async function persistBonusSeen(bonus) {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.post('/api/bonus/seen', {
      id: bonus.id,
    })
  ).catch(handleMiddlewareError);
  return data;
}

export async function getBonuses() {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.get(`/api/getBonuses?t=${Date.now()}`)
  ).catch(handleMiddlewareError);
  return data;
}

export async function getChallengeBonusProgress() {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.get(`/api/bonus/getChallengeProgress?t=${Date.now()}`)
  ).catch(handleMiddlewareError);
  return data;
}

export async function getChallengeBonusStats(bonusId) {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.get(`/api/bonus/getChallengeBonusStats/${bonusId}?t=${Date.now()}`)
  ).catch(handleMiddlewareError);
  return data;
}
