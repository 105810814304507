import { t } from 'i18next';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAnalyticsContext } from '../shared/hooks/use-analytics';
import { useInterval } from '../shared/hooks/use-interval';
import { isInAppBrowser, isIos, isPwa, isAndroid } from '../shared/utils';

const InstallPrompt = createContext();

function InstallPromptContext({ children }) {
  const installPrompt = useInstall();
  const persistedPrompt = useMemo(() => installPrompt, [installPrompt]);
  return (
    <InstallPrompt.Provider value={persistedPrompt}>
      {children}
    </InstallPrompt.Provider>
  );
}

function useInstall(onInstalled) {
  const [installPrompt, setInstallPrompt] = useState(null);
  const onBeforeInstallPrompt = (event) => {
    event.preventDefault();
    setInstallPrompt(event);
  };
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);
    window.addEventListener('appinstalled', () => {
      window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt);
      onInstalled && onInstalled();
    });
  });
  return installPrompt;
}

function Install({
  logger,
  onClose,
  isDialogMode = false,
  autoInstall = false,
  installing = false,
  hasInstalled = false,
}) {
  const navigate = useNavigate();
  const { track } = useAnalyticsContext();

  const installPrompt = useContext(InstallPrompt);

  const [isInstalling, setIsInstalling] = useState(false);
  const [isAutoInstalling, setIsAutoInstalling] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);
  const [isInstallEnabled, setIsInstallEnabled] = useState(isDialogMode);

  const isInfoButtonShowing =
    isDialogMode && !autoInstall && !isInstalling && !isInstalled;
  const isRatingsShowing = !isDialogMode;
  const isAppSupportShowing = !isDialogMode;
  const isMarinSmall = isDialogMode;

  useEffect(() => {
    if (!isDialogMode) {
      if (isPwa()) {
        navigate('/', { replace: true });
        return;
      }
      const newIsInstallEnabled =
        !isInstalled && !isInstalling && installPrompt !== null;
      setIsInstallEnabled(newIsInstallEnabled);
    }
  }, [installPrompt, isInstalling, isInstalled]);

  useInterval(async () => {
    if (isAutoInstalling) {
      try {
        if (isInstallEnabled) await onInstall();
        else setIsAutoInstalling(false);
      } catch {
        setIsInstalling(false);
      }
    }
  }, 1000);

  const onInstall = async () => {
    if (isDialogMode && isInAppBrowser())
      window.location.href = `intent:${window.location.protocol}//${window.location.host}/autoInstall#Intent;end`;
    else {
      if (installPrompt) {
        setIsInstalling(true);
        setIsAutoInstalling(false);
        const result = await installPrompt?.prompt();
        if (result.outcome === 'accepted') {
          logger?.event('click', { target: 'confirm-install-prompt' });
          if (isDialogMode)
            navigate('/installing', {
              replace: true,
              state: window.location.pathname,
            });
          else {
            setTimeout(() => {
              setIsInstalling(false);
              setIsInstalled(true);
            }, 10000);
          }
        } else {
          logger?.event('click', { target: 'reject-install-prompt' });
          setIsInstalling(false);
        }
      } else {
        setIsInstallEnabled(false);
        if (isAndroid())
          navigate('/installed', {
            replace: true,
            state: window.location.pathname,
          });
      }
    }
  };

  const location = useLocation();
  const [params] = useSearchParams();
  const pathNameParam = params.get('return');
  const returnPathname =
    location.state || (pathNameParam && atob(pathNameParam)) || '/';
  //state is not null when it was navigation from dialog
  //param is not null if reopened in browser

  const onOpenApp = () => {
    logger?.event('click', { target: 'open-app' });
    if (isPwa()) {
      navigate('/', { replace: true });
    } else {
      window.open(
        `${window.location.protocol}//${window.location.host}/installed${
          returnPathname ? `?return=${btoa(returnPathname)}` : ''
        }`,
        '_blank'
      );
      window.close();
    }
  };

  const onMoreInfo = async () => {
    if (isInAppBrowser())
      window.location.href = `intent:${window.location.protocol}//${window.location.host}/install#Intent;end`;
    else
      navigate('/install', {
        replace: true,
        state: window.location.pathname,
      });
  };

  /* 
  const aboutApp = isDialogMode
    ? `${t('Get Welcome Bonus!')} ${t('Have fun with Boxo Game!')} ${t(
        'Win real money!'
      )} ${t('Withdraw your winnings in USDT.')}`
    : `${t('Get Welcome Bonus!')} ${t('Have fun with Boxo Game!')} ${t(
        'Win real money!'
      )} ${t('Withdraw your winnings in USDT.')} ${t(
        'Register and get free 500 BOXO.'
      )}`;
 */
  const aboutApp = isDialogMode
    ? `${t('Get Welcome Bonus!')} ${t('Have fun with Boxo Game!')}`
    : `${t('Get Welcome Bonus!')} ${t('Have fun with Boxo Game!')} ${t(
        'Register and get free 500 BOXO.'
      )}`;

  const aboutApp_ = isDialogMode
    ? `${t('Get Welcome Bonus!')} ${t('Have fun with Boxo Game!')} ${t(
        'Win real money!'
      )}`
    : `${t('Get Welcome Bonus!')} ${t('Have fun with Boxo Game!')} ${t(
        'Win real money!'
      )} ${t('Register and get free 500 BOXO.')}`;

  useEffect(() => {
    if (isPwa()) {
      //successfully installed
      track('PwaInstalled');
      setIsInstalling(false);
      setIsInstalled(true);
      navigate(returnPathname, { replace: true });
    }

    if (isIos()) {
      if (process.env.NODE_ENV === 'production')
        navigate('/', { replace: true });
    }

    if (autoInstall) {
      //fake installation... wait for user's action to install
      setIsAutoInstalling(true);
      setTimeout(() => {
        setIsAutoInstalling(false);
      }, 10000);
    }

    if (installing) {
      //real installation
      setIsInstalling(true);
      setTimeout(() => {
        setIsInstalling(false);
        setIsInstalled(true);
      }, 10000);
    }

    if (hasInstalled) {
      setIsInstalled(true);
    }
  }, []);

  const screens = [
    '/img/store/4.jpg',
    '/img/store/2.jpg',
    '/img/store/3.jpg',
    '/img/store/0.jpg',
  ];

  const ratings = [
    { rating: 5, value: 80, img: '/img/store/star.svg' },
    { rating: 4, value: 10, img: '/img/store/star.svg' },
    { rating: 3, value: 4, img: '/img/store/star.svg' },
    { rating: 2, value: 1, img: '/img/store/star.svg' },
    { rating: 1, value: 5, img: '/img/store/star-half.svg' },
  ];
  const rating = ratings
    .reduce((s, b) => s + (b.value * b.rating) / 100, 0)
    .toFixed(1);

  const support = [
    { header: 'Website', value: `https://${window.location.host}` },
    { header: 'Support email', value: `info@boxo.trade` },
  ];

  return (
    <div
      className="store p-4"
      style={{
        touchAction: 'pan-y',
        backgroundColor: 'white',
        width: '100vw',
        color: 'rgb(32, 33, 36)',
        fontFamily: '"Google Sans",Roboto,Arial,sans-serif',
        letterSpacing: '0.25px',
      }}
    >
      {isDialogMode && (
        <img
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: '65px',
            height: '55px',
            padding: '0px 20px 10px 20px',
          }}
          src="/img/close.svg"
          alt="close"
          onClick={onClose}
        />
      )}

      <div className="mt-2 mb-3">
        <div className="row">
          <div className="col-3" style={{ position: 'relative' }}>
            {(isAutoInstalling || isInstalling) && (
              <div className="store-loader">
                <svg className="circular" viewBox="25 25 50 50">
                  <circle
                    className="path"
                    cx="50"
                    cy="50"
                    r="20"
                    fill="none"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                </svg>
              </div>
            )}
            <img
              className={
                isAutoInstalling || isInstalling
                  ? 'logo-img-loading'
                  : 'logo-img'
              }
              src="/favicon.png"
              alt="boxo-logo"
            />
          </div>
          <div className="col">
            <div
              className=""
              style={{
                width: '100%',
                lineHeight: '32px',
                fontSize: '24px',
                fontWeight: 500,
              }}
            >
              Boxo
            </div>
            <div style={{ marginTop: '2px' }}>
              <div
                style={{
                  lineHeight: '24px',
                  fontSize: '16px',
                  letterSpacing: '0.1px',
                  fontWeight: '500',
                  color: '#01875f',
                }}
              >
                Boxo, Inc.
              </div>
              <div
                style={{
                  lineHeight: '16px',
                  fontSize: '12px',
                  color: 'rgb(95, 99, 104)',
                }}
              >
                {t('In-app purchases')}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isRatingsShowing && (
        <div
          style={{
            margin: '16px 0 24px 0px',
            padding: '12px 0',
          }}
        >
          <div className="row text-center" style={{ fontSize: '14px' }}>
            <div className="col-4">
              <div
                style={{
                  lineHeight: '24px',
                  fontWeight: '500',
                }}
              >
                {rating}
                <img
                  style={{
                    height: '11px',
                    width: '11px',
                    margin: '0 0 2px 2px',
                  }}
                  src="./img/store/star-black.svg"
                  alt="star"
                />
              </div>

              <div
                style={{
                  lineHeight: '20px',
                  fontSize: '12px',
                  color: 'rgb(95, 99, 104)',
                }}
              >
                Rating
              </div>
            </div>

            <div className="col-4 col-left-border">
              <div
                style={{
                  lineHeight: '24px',
                  fontWeight: '500',
                }}
              >
                12.2K
              </div>

              <div
                style={{
                  lineHeight: '20px',
                  fontSize: '12px',
                  color: 'rgb(95, 99, 104)',
                }}
              >
                Downloads
              </div>
            </div>

            <div className="col-4 col-left-border">
              <div
                style={{
                  lineHeight: '24px',
                  fontWeight: '500',
                }}
              >
                <span
                  style={{
                    borderBlockColor: 'rgb(32, 33, 36)',
                    borderStyle: 'solid',
                    borderWidth: 'thin',
                  }}
                >
                  18+
                </span>
              </div>
              <div
                style={{
                  lineHeight: '20px',
                  fontSize: '12px',
                  color: 'rgb(95, 99, 104)',
                }}
              >
                Age
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row flex-nowrap g-3">
        {isInfoButtonShowing && (
          <div className="col-6">
            <div
              className="btn btn-secondary-store text-nowrap"
              onClick={onMoreInfo}
            >
              {t('More Info')}
            </div>
          </div>
        )}

        {!isInstalled && (
          <div className="col">
            <div
              className={`btn btn-primary-store${
                isInstallEnabled && !isInstalling ? '' : ' disabled'
              }`}
              onClick={onInstall}
            >
              {isInstalling ? t('Installing...') : t('Install')}
            </div>
          </div>
        )}
        {isInstalled && (
          <div className="col">
            <div className="btn btn-primary-store" onClick={onOpenApp}>
              {t('Open')}
            </div>
          </div>
        )}
      </div>

      <div className={isMarinSmall ? 'mt-4' : 'mt-5'}>
        <div className="row flex-nowrap overflow-auto hide-scroll-bars g-3">
          {screens.map((screen, idx) => (
            <div className="col-auto" key={idx}>
              <img
                style={{
                  boxShadow:
                    '0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)',
                  borderRadius: '8px',
                  height: '204px',
                  minWidth: '92px',
                }}
                src={screen}
                alt="boxo-logo"
              />
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          margin: '20px 0 20px',
          fontSize: '18px',
          fontWeight: '500',
        }}
      >
        {t('About this app')}
      </div>

      <div
        style={{
          fontSize: '14px',
          color: 'rgb(95, 99, 104)',
          lineHeight: '20px',
        }}
      >
        {aboutApp}
      </div>

      {isRatingsShowing && (
        <>
          <div
            style={{
              margin: '20px 0 20px',
              fontSize: '18px',
              fontWeight: '500',
            }}
          >
            {t('Ratings and reviews')}
          </div>

          <div className="mt-2">
            <div className="row g-3">
              <div className="col-auto">
                <div style={{ fontSize: '56px', lineHeight: '64px' }}>
                  {rating}
                </div>
                <div
                  style={{
                    fontSize: '17px',
                    lineHeight: '16px',
                    textWrap: 'nowrap',
                    color: 'rgb(1, 135, 95)',
                  }}
                >
                  {ratings.map((r, idx) => (
                    <img
                      key={idx}
                      style={{
                        height: '14px',
                        width: '14px',
                        margin: '0 0 0 2px',
                      }}
                      src={r.img}
                      alt="star"
                    />
                  ))}
                </div>
              </div>
              <div className="col">
                <div style={{ marginTop: '0px' }}>
                  {ratings.map((r, idx) => (
                    <div key={idx}>
                      <div className="row">
                        <div
                          className="col-auto"
                          style={{
                            lineHeight: '16px',
                            fontSize: '12px',
                            color: 'rgb(95, 99, 104)',
                            width: '20px',
                          }}
                        >
                          {ratings.length - idx}
                        </div>
                        <div className="col">
                          <div
                            className="progress"
                            style={{
                              height: '10px',
                              marginTop: '4px',
                              borderRadius: '9999px',
                              backgroundColor: 'rgb(232, 234, 237)',
                            }}
                          >
                            <div
                              className="progress-bar"
                              style={{
                                width: `${r.value}%`,
                                borderRadius: '9999px',
                                backgroundColor: 'rgb(1, 135, 95)',
                              }}
                              role="progressbar"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {isAppSupportShowing && (
        <>
          <div
            style={{
              margin: '20px 0 20px',
              fontSize: '18px',
              fontWeight: '500',
            }}
          >
            {t('App support')}
          </div>

          {support.map((s, idx) => (
            <div
              key={idx}
              style={{
                margin: '12px 0 12px',
              }}
            >
              <div
                className="font-weight-bold"
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                {s.header}
              </div>

              <div
                style={{
                  color: 'rgb(95, 99, 104)',
                  fontSize: '14px',
                }}
              >
                {s.value}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

function InstallDialog({
  show,
  onClose,
  installPrompt,
  autoInstall = false,
  logger,
}) {
  const onCloseClick = async () => {
    logger?.event('click', { target: 'close-install-dialog' });
    onClose();
  };

  return (
    <>
      {show && (
        <div
          style={{
            position: 'absolute',
            content: '',
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
            minHeight: '100vh',
            overflow: 'hidden',
            zIndex: 1998,
            backgroundColor: '#0000009e',
          }}
          onClick={onCloseClick}
        />
      )}
      <div
        style={{
          position: 'absolute',
          overflow: 'hidden',
          top: '100%',
          transform: show ? 'translateY(-100%)' : 'translateY(0%)',
          transition: 'transform 250ms ease-in-out, opacity 250ms ease-in-out',
          opacity: show ? 100 : 0,
          zIndex: 1999,
        }}
      >
        {show && (
          <Install
            installPrompt={installPrompt}
            autoInstall={autoInstall}
            isDialogMode={true}
            logger={logger}
            onClose={onCloseClick}
          />
        )}
      </div>
    </>
  );
}

export { InstallPromptContext, Install, InstallDialog };
