import { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';

import { initializeApp } from '@firebase/app';
import { getAnalytics, logEvent } from '@firebase/analytics';

import {
  compareVersions,
  isAndroid,
  isIos,
  isNativeApp,
  isTelegram,
} from '../utils';
import { useRef } from 'react';
import { useNativeAppContext } from './use-native-app';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { createContext } from 'react';
import { useContext } from 'react';
import { useLoggerContext } from './use-logger';

const Analytics = createContext();
export function AnalyticsContext({ children }) {
  const analytics = useAnalytics();
  const persistedAnalytics = useMemo(() => analytics, [analytics]);
  return (
    <Analytics.Provider value={persistedAnalytics}>
      {children}
    </Analytics.Provider>
  );
}
export const useAnalyticsContext = () => useContext(Analytics);

function useAnalytics() {
  const [isFbActive, setIsFbActive] = useState(false);
  const [isTikTokActive, setIsTikTokActive] = useState(false);
  const [isFireBaseActive, setIsFireBaseActive] = useState(false);
  const [isNativeFireBaseActive, setIsNativeFireBaseActive] = useState(false);
  const firebaseApp = useRef();

  const {
    version: nativeAppVersion,
    firebaseEvent: nativeFirebaseEvent,
    firebaseSetUser: nativeFirebaseSetUser,
  } = useNativeAppContext();

  const logger = useLoggerContext();

  //Track pathname change
  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.includes('app/p/')) {
      pageView(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    const initFireBase = () => {
      const webFirebaseConfig = {
        apiKey: 'AIzaSyCeJxTEvbs9OsxGWOLbj8r0pTd1wmsh8-4',
        authDomain: 'boxoapp-4ecef.firebaseapp.com',
        projectId: 'boxoapp-4ecef',
        storageBucket: 'boxoapp-4ecef.firebasestorage.app',
        messagingSenderId: '489478531661',
        appId: '1:489478531661:web:d84a98c95f691112a48a04',
      };

      /*
        const iOsFirebaseConfig = {
          apiKey: 'AIzaSyBhjXXKmRi9qAFueJ4SAhae30OgY0GD02E',
          authDomain: 'boxoapp-4ecef.firebaseapp.com',
          projectId: 'boxoapp-4ecef',
          storageBucket: 'boxoapp-4ecef.firebasestorage.app',
          messagingSenderId: '489478531661',
          appId: '1:489478531661:ios:82ac6b56ad2065e8a48a04',
        };
        const androidFirebaseConfig = {
          apiKey: 'AIzaSyBUQf7nHZtAQ1p7i9O_MbNCt10f99IXXkQ',
          authDomain: 'boxoapp-4ecef.firebaseapp.com',
          projectId: 'boxoapp-4ecef',
          storageBucket: 'boxoapp-4ecef.firebasestorage.app',
          messagingSenderId: '489478531661',
          appId: '1:489478531661:android:ffac6ddf764a401ea48a04',
        };
        const firebaseConfig_ =
          process.env.NODE_ENV !== 'production'
            ? webFirebaseConfig
            : isIos()
            ? iOsFirebaseConfig
            : isAndroid()
            ? androidFirebaseConfig
            : null;
      */

      const firebaseConfig = webFirebaseConfig;
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
      firebaseApp.current = analytics;
      setIsFireBaseActive(true);
    };

    const initFb = () => {
      const advancedMatching = {};
      const options = {
        autoConfig: true,
        debug: false,
      };
      if (window.location.hostname === 'boxo-fun.online') {
        ReactPixel.init('1356254651724329', advancedMatching, options);
        setIsFbActive(true);
      }
    };

    const initTikTok = () => {
      const advancedMatching = {}; // optional, more info: https://ads.tiktok.com/help/article?aid=10007891
      const options = {
        debug: false, // enable logs
      };
      if (window.location.hostname === 'boxo.trade') {
        TiktokPixel.init('CSH5L7RC77U5IJO1SELG', advancedMatching, options);
        setIsTikTokActive(true);
      }
    };
    if (!isNativeApp()) initFireBase(); //Web firebase
    initTikTok();
    initFb();
  }, []);

  useEffect(() => {
    if (isNativeApp() && compareVersions(nativeAppVersion, '1.9.0') >= 0)
      setIsNativeFireBaseActive(true); //Native firebase
  }, [nativeAppVersion]);

  const pageView = (pathname) => {
    const data = {
      platform: isIos() ? 'ios' : isAndroid() ? 'android' : 'unknown',
      app: isTelegram() ? 'telegram' : isNativeApp() ? 'native' : 'unknown',
      screen_class: 'View',
      screen_name: pathname,
    };

    console.log('ANALYTICS - Page view', ReactPixel, TiktokPixel);
    if (process.env.NODE_ENV !== 'production') return;
    if (isFbActive) ReactPixel?.pageView();
    if (isTikTokActive) TiktokPixel?.pageView();
    if (isFireBaseActive) logEvent(firebaseApp.current, 'page_view');
    if (isNativeFireBaseActive) nativeFirebaseEvent('screen_view', data);
  };

  const track = (event, data_ = {}) => {
    const data = {
      ...data_,
      platform: isIos() ? 'ios' : isAndroid() ? 'android' : 'unknown',
      app: isTelegram() ? 'telegram' : isNativeApp() ? 'native' : 'unknown',
    };

    console.log('ANALYTICS - track event:', event, data);
    //if (process.env.NODE_ENV !== 'production') return;
    if (isFbActive) ReactPixel?.track(event, data);
    if (isTikTokActive) TiktokPixel?.track(event, data);
    if (isFireBaseActive) logEvent(firebaseApp.current, event);
    if (isNativeFireBaseActive) nativeFirebaseEvent(event, data);
  };

  const setUser = (userId = 'unknownUser', userProperties = null) => {
    console.log('ANALYTICS - set user:', userId, userProperties);
    if (isNativeFireBaseActive) nativeFirebaseSetUser(userId, userProperties);
  };

  return { track, setUser };
}
