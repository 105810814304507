import { t } from 'i18next';
import { createContext } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Loader } from '../../pages/loader';
import { compareVersions, isAndroid, isIos, isNativeApp } from '../utils';
import { useLoggerContext } from './use-logger';
import { useNativeAppContext } from './use-native-app';

const Appodeal = createContext();
export function AppodealContext({ children }) {
  const appodealProps = useAppodeal();
  const persistedAppodealProps = useMemo(() => appodealProps, [appodealProps]);
  return (
    <Appodeal.Provider value={persistedAppodealProps}>
      {children}
    </Appodeal.Provider>
  );
}
export const useAppodealContext = () => useContext(Appodeal);

function useAppodeal() {
  const nativeApp = useNativeAppContext();
  const logger = useLoggerContext();
  const [isInited, setIsInited] = useState(false);

  const [isLoadedRewarded, setIsLoadedRewarded] = useState(false);
  const [isLoadedInterstitial, setIsLoadedInterstitial] = useState(false);

  const listeners = useRef();
  function setListeners(
    callbacks = { onDone: null, onFail: null, onShowing: null }
  ) {
    listeners.current = callbacks;
  }

  const handleShowAppodeal = (event) => {
    logger?.event('appodeal', event);
    const { onDone, onFail, onShowing } = listeners.current ?? {};

    if (event?.name === 'done') onDone && onDone(event?.data);
    if (event?.name === 'failed') onFail && onFail('appodeal is failed');
    if (event?.name === 'update') {
      if (event?.data === 'init done') setIsInited(true);
      if (event?.data?.event === 'loaded') {
        if (event?.data?.adType === 'rewarded') setIsLoadedRewarded(true);
        if (event?.data?.adType === 'interstitial')
          setIsLoadedInterstitial(true);
      }
      onShowing && onShowing(event?.data);
    }
  };

  useEffect(() => {
    if (isLoadedInterstitial || isLoadedRewarded) setIsInited(true);
  }, [isLoadedInterstitial, isLoadedRewarded]);

  function init() {
    if (isInited) return true;
    if (compareVersions(nativeApp?.version, '1.12.0') < 0) return false;
    if (nativeApp?.trackingPermissionsStatus === 'undetermined')
      nativeApp?.requestTrackingPermissions();
    return true;
  }

  //Try init if tracking permissions was asked
  useEffect(() => {
    if (isInited) return;
    if (!isNativeApp()) return;
    if (compareVersions(nativeApp?.version, '1.12.0') < 0) return;

    nativeApp?.addAppodealListener(handleShowAppodeal);

    if (
      !nativeApp?.trackingPermissionsStatus ||
      nativeApp?.trackingPermissionsStatus === 'undetermined'
    )
      return; //Can not auto init

    if (process.env.NODE_ENV === 'production') nativeApp?.initAppodeal('prod');
    else nativeApp?.initAppodeal('dev');
  }, [nativeApp?.version, nativeApp?.trackingPermissionsStatus]);

  const showRewarded = () => nativeApp?.showAppodeal('rewarded');
  const showInterstitial = () => nativeApp?.showAppodeal('interstitial');

  return {
    isLoadedRewarded,
    isLoadedInterstitial,
    showRewarded,
    showInterstitial,
    setListeners,
    init,
    isInited,
  };
}

export function AppodealContainer({
  format = 'Long',
  onDone,
  onFail,
  onShowing,
}) {
  const appodeal = useAppodealContext();
  useEffect(() => {
    appodeal.setListeners({ onDone, onFail, onShowing });
    if (!appodeal.init()) {
      onFail && onFail('appodeal is not supported');
      return;
    }
  }, []);

  const [shouldWait, setShouldWait] = useState(true);
  useEffect(() => {
    if (!appodeal?.isInited) return;
    if (format === 'Long') {
      if (appodeal.isLoadedRewarded) appodeal.showRewarded();
      else if (!shouldWait) onFail && onFail('appodeal rewarded is not loaded');
      else setTimeout(() => setShouldWait(false), 3500);
    }
    if (format === 'Short') {
      if (appodeal.isLoadedInterstitial) appodeal.showInterstitial();
      else if (!shouldWait)
        onFail && onFail('appodeal interstitial is not loaded');
      else setTimeout(() => setShouldWait(false), 3500);
    }
  }, [format, appodeal?.isInited, shouldWait]);
  return (
    <>
      <Loader overlay />
    </>
  );
}
