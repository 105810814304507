import { handleError, maybe_refresh_auth_token } from './utils';

export async function balance() {
  const { data } = await maybe_refresh_auth_token((api) =>
    api.get(`/user?t=${Date.now()}`)
  ).catch(handleError);
  return data;
}

export async function log(balance, winBalance) {
  const { data } = await maybe_refresh_auth_token((api) =>
    api.post('/log', {
      balance: `${balance}`,
      winBalance: `${winBalance}`,
    })
  ).catch(handleError);
  return data;
}

/* 
boxes = {
originalPrize: 1.2
prize: 1.2
x1: 57989.87074680709
x2: 57993.9372531929
y1: 1714605740500
y2: 1714605740500
}
*/
export async function sendBet(priceTimestamp, bet, boxes) {
  const newBoxes = boxes.map((b) => {
    return {
      prize: b.originalPrize,
      minTimestamp: b.y1,
      maxTimestamp: b.y2,
      minPrice: b.x1,
      maxPrice: b.x2,
    };
  });

  try {
    const resp = await maybe_refresh_auth_token((api) =>
      api.post('/bet', {
        priceTimestamp,
        currency: 'USD',
        amount: `${bet}`,
        boxes: newBoxes,
      })
    );
    return resp?.data;
  } catch (e) {
    const resp = handleError(e);
    return resp?.data;
  }
}
