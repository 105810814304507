import { t } from 'i18next';
import { useRef, useState } from 'react';
import { createContext } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import {
  getBonuses,
  getChallengeBonusProgress,
  persistBonusSeen,
  claimBonusPersisted,
} from '../api/bonus';
import { isNativeApp } from '../utils';
import { useSessionStorage } from './use-hooks';
import { useInterval } from './use-interval';
import { useLoggerContext } from './use-logger';
import { useUserContext } from './use-user';

const Bonuses = createContext();
export function BonusesContext({ children }) {
  const bonusesProps = useBonuses();
  const persistedBonusesProps = useMemo(() => bonusesProps, [bonusesProps]);
  return (
    <Bonuses.Provider value={persistedBonusesProps}>
      {children}
    </Bonuses.Provider>
  );
}
export const useBonusesContext = () => useContext(Bonuses);

function useBonuses() {
  //Old format: {name: "Bonus", descr: "Amount $5", amount: "500", expiration: 1703361351044, claimed: true}
  const [bonuses, setBonuses] = useState([]);
  const [bonusTime, setBonusTime] = useSessionStorage('new-user-bonus-time', 0);

  const [isLoading, setIsLoading] = useState(false);
  const { balance, isLogged, user } = useUserContext();
  const logger = useLoggerContext();

  const isDirty = useRef();
  useEffect(() => {
    isDirty.current = true;
  }, []);

  function getBonusAttr(bonus) {
    const bonusType = bonus?.typeId;
    if (bonusType === '2ndWelcomeBonusFreeBoxes') {
      return {
        name: t('Welcome bonus'),
        imgUrl: '/img/bonus-box.svg',
        hidden: false,
        isNew: false,
        adBoost: 0,
        useAds: false,
        useAdBoost: false,
        order: 1,
      };
    } else if (bonusType === 'WelcomeBonusFreeBoxes') {
      return {
        name: t('Welcome bonus'),
        imgUrl: '/img/bonus-box.svg',
        hidden: false,
        isNew: true,
        adBoost: 0,
        useAds: false,
        useAdBoost: false,
        order: 1,
      };
    } else if (bonusType === 'PushNotificationsBonus')
      return {
        name: t('Notifications bonus'),
        imgUrl: '/img/plane.svg',
        hidden: false,
        isNew: true,
        adBoost: 0,
        useAds: false,
        useAdBoost: false,
        order: 2,
      };
    if (bonusType === 'ChallengeBonus') {
      return {
        name: t('Achievement bonus'),
        imgUrl: '/img/target.svg', //progress.svg',
        hidden: true,
        isNew: bonus?.params?.limit - bonus?.progress <= 0,
        adBoost: bonus?.params?.adBoost ?? 0,
        useAds: false, //bonus?.params?.adBoost ? 'Short' : false, //TODO random
        useAdBoost:
          (isNativeApp() || process.env.NODE_ENV !== 'production') &&
          bonus?.params?.adBoost
            ? 'Long'
            : false,
        order: 3,
      };
    } else if (bonusType === 'RecoveryBonus') {
      const enabled =
        bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;
      return {
        name: t('Recovery bonus'),
        descr: enabled ? null : t('Recently claimed'),
        imgUrl: '/img/heart.svg',
        disabled: !enabled,
        hidden: balance >= 50,
        isNew: enabled,
        adBoost: bonus?.params?.adBoost ?? 0,
        useAds: false, //bonus?.params?.adBoost ? 'Long' : false,
        useAdBoost:
          (isNativeApp() || process.env.NODE_ENV !== 'production') &&
          bonus?.params?.adBoost
            ? 'Long'
            : false,
        order: 2,
      };
    } else if (bonusType === 'AdBonus') {
      const enabled =
        bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;
      return {
        name: t('Health bonus'),
        descr: enabled ? null : t('Recently claimed'),
        imgUrl: '/img/health.svg',
        disabled: !enabled,
        hidden: false,
        isNew: enabled,
        adBoost: bonus?.params?.adBoost ?? 0,
        useAds: false, //bonus?.params?.adBoost ? 'Long' : false,
        useAdBoost:
          (isNativeApp() || process.env.NODE_ENV !== 'production') &&
          bonus?.params?.adBoost
            ? 'Long'
            : false,
        order: 4,
      };
    } else if (bonusType === 'StreakBonus') {
      const enabled =
        bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;
      return {
        name: t('Daily bonus'),
        descr: enabled ? null : t('Recently claimed'),
        imgUrl: '/img/calendar.svg',
        disabled: !enabled,
        hidden: false,
        isNew: enabled,
        adBoost: bonus?.params?.adBoost ?? 0,
        useAds: false, //bonus?.params?.adBoost ? 'Long' : false,
        useAdBoost:
          (isNativeApp() || process.env.NODE_ENV !== 'production') &&
          bonus?.params?.adBoost
            ? 'Long'
            : false,
        order: 5,
      };
    } else
      return {
        name: `Unknown: ${bonusType}`,
        imgUrl: '/img/bonus-box.svg',
        hidden: true,
      };
  }

  const visibleBonuses = useRef([]);
  visibleBonuses.current =
    bonuses
      ?.map((b) => ({ ...b, ...getBonusAttr(b) }))
      .filter((b) => !b.hidden && (!b.expired || b.expired > Date.now())) ?? [];

  async function fetchData() {
    //console.log('================ UPDATE BONUSES ============');

    setIsLoading(true);

    function parse(params) {
      try {
        return params ? JSON.parse(params) : null;
      } catch {
        return { amount: 0 };
      }
    }

    try {
      const resps = await Promise.all([
        getBonuses(),
        getChallengeBonusProgress(),
      ]);

      const [bonusResponse, challengeBonusProgress] = resps;
      const progress = challengeBonusProgress.progress;
      const data = bonusResponse?.bonuses;

      const newBonuses = !data
        ? []
        : data
            .map((b) => {
              const newBonus = {
                ...b,
                typeId: b.type_id,
                created: b.created_at ? new Date(b.created_at).getTime() : null,
                availableAt: b.available_at
                  ? new Date(b.available_at).getTime()
                  : null,
                expired: b.expired_at
                  ? new Date(b.expired_at).getTime()
                  : bonusTime,
                lastSeenAt: b.last_seen_at
                  ? new Date(b.last_seen_at).getTime()
                  : null,
                type: b.type,
                params: parse(b.params),
                descr: '',
                progress: b.type_id === 'ChallengeBonus' ? progress : null,
              };
              const attr = getBonusAttr(newBonus);
              return { ...newBonus, ...attr };
            })
            //.filter((b) => !b.hidden) -- UI filters it
            .sort((a, b) => a.order - b.order); //a.name.localeCompare(b.name));
      setBonuses(newBonuses);
      isDirty.current = false;
      setIsLoading(false);

      if (!data)
        logger?.event('error', {
          msg: 'bonusResponse is null',
          bonusResponse,
        });
      else
        logger?.event('bonus', {
          loaded: newBonuses?.map((b) => b.id),
        });

      return newBonuses;
    } catch (e) {
      setBonuses([]);
      setIsLoading(false);
      logger?.event('error', {
        msg: `Fetching bonuses failed: ${e?.message}`,
      });
      return [];
    }
  }

  useEffect(() => {
    if (!bonusTime) setBonusTime(Date.now() + 24 * 60 * 60 * 1000);
    if (!isLogged) setBonuses([]);
    else fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, user?.email]);

  function claimBonus(bonus, onSuccess, onFailed, useAdBoost = false) {
    if (!isLogged) {
      onFailed('Can not claim bonus. Please login.');
      return;
    }

    claimBonusPersisted(bonus, useAdBoost)
      .then((data) => {
        if (data?.success) {
          logger?.event('bonus', { claimed: bonus, boosted: useAdBoost, data });

          //Remove bonus from the list
          const newBonuses = bonuses
            ? bonuses.filter((b) => !(b.id === bonus.id))
            : [];
          setBonuses(newBonuses);

          const amount = data.amount * 1;
          onSuccess(amount);
          invalidateBonuses();
        } else {
          logger?.event('error', {
            msg: 'claimBonusPersisted error',
            bonus,
            data,
          });

          if (data?.error === 'bonus is not found')
            onFailed(t('Bonus has been already spent'));
          else if (data?.error) onFailed(`Bonus error: ${data?.error}`);
          else onFailed(`Unknown bonus error: ${bonus?.id}`);
        }
      })
      .catch((e) => {
        logger?.event('error', {
          msg: 'claimBonusPersisted failed',
          bonus,
        });
        onFailed(e);
      });
  }

  function setBonusSeen(bonus) {
    if (!isLogged || !bonus) return;
    persistBonusSeen(bonus);
  }

  async function getActualBonuses() {
    const bonusData =
      (isDirty?.current && isLogged ? await fetchData() : bonuses) ?? [];
    return bonusData?.map((b) => {
      const attr = getBonusAttr(b);
      return { ...b, ...attr };
    });
  }

  useInterval(() => {
    isLogged && fetchData();
  }, 60000);

  async function getActualChallengeBonus() {
    const bonusData = await getActualBonuses();
    return bonusData.find(
      (b) => b.typeId === 'ChallengeBonus' && b.expired > Date.now()
    );
  }

  async function getActualWelcomeBonus() {
    const bonusData = await getActualBonuses();
    return bonusData.find(
      (b) => b.typeId === 'WelcomeBonusFreeBoxes' && b.expired > Date.now()
    );
  }

  async function getActualRecoveryBonus() {
    const bonusData = await getActualBonuses();
    return bonusData.find((b) => b.typeId === 'RecoveryBonus');
  }

  async function getActualPushNotificationsBonus() {
    const bonusData = await getActualBonuses();
    return bonusData.find(
      (b) => b.typeId === 'PushNotificationsBonus' && b.expired > Date.now()
    );
  }

  async function getActualStreakBonus() {
    const bonusData = await getActualBonuses();
    return bonusData.find((b) => b.typeId === 'StreakBonus');
  }

  async function getActualAdsBonus() {
    const bonusData = await getActualBonuses();
    return bonusData.find((b) => b.typeId === 'AdBonus');
  }

  async function getActualNewBonuses() {
    const bonusData = await getActualBonuses();
    return bonusData.filter((b) => b.isNew && !b.hidden);
  }

  function invalidateBonuses() {
    isDirty.current = true;
  }

  return {
    bonuses,
    visibleBonuses: visibleBonuses.current,
    invalidateBonuses,
    getActualBonuses,
    claimBonus,
    setBonusSeen,
    isLoading,
    getActualChallengeBonus,
    getActualWelcomeBonus,
    getActualPushNotificationsBonus,
    getActualRecoveryBonus,
    getActualAdsBonus,
    getActualNewBonuses,
    getActualStreakBonus,
  };
}
