import {
  handleMiddlewareError,
  maybe_refresh_auth_token_middlware,
} from './utils';
import { api_middleware } from './api-client';

export async function fetchProducts() {
  try {
    const response = await api_middleware().get(`/api/products`);
    return response?.data;
  } catch (e) {
    return { products: [] };
  }
}

export async function deliverPurchase(receipt) {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.post('/api/products/purchase', {
      productId: receipt?.productId?.toLowerCase(),
      receipt,
    })
  ).catch(handleMiddlewareError);
  return data;
}
