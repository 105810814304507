import { t } from 'i18next';
import { createContext } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { compareVersions, isNativeApp } from '../utils';
import { AdMobContainer } from './use-admob';
import { AdsenseContainer } from './use-adsense';
import { AppodealContainer } from './use-appodeal';
import { useLocalStorage } from './use-hooks';
import { useLoggerContext } from './use-logger';
import { MonetagContainer } from './use-monetag';
import { useNativeAppContext } from './use-native-app';
import { useSoundContext } from './use-sound';

const Ads = createContext();
export function AdsContext({ children }) {
  const adsProps = useAds();
  const persistedAdsProps = useMemo(() => adsProps, [adsProps]);
  return <Ads.Provider value={persistedAdsProps}>{children}</Ads.Provider>;
}
export const useAdsContext = () => useContext(Ads);

function useAds() {
  const minShowInterval = 4 * 60 * 1000;
  const [lastShowTime, setLastShowTime] = useLocalStorage('lastAdsShowTime', 0);
  const isReady = () => Date.now() >= lastShowTime + minShowInterval;
  const onShowing = () => setLastShowTime(Date.now());

  return { isReady, onShowing, lastShowTime };
}

export function AdsContainer({ playTime, format, onDone, onFail, onShowing }) {
  const nativeApp = useNativeAppContext();
  const logger = useLoggerContext();
  const { html5Click } = useSoundContext();
  const ads = useAdsContext();

  const doFail = (status) => {
    try {
      logger?.event('error', {
        msg: `ads failed: ${JSON.stringify(status)}`,
        ads: adsList[adsIdx].name,
      });
    } catch (e) {}
    html5Click();
    //if (false && settings?.canSkipAds) onDone && onDone();
    //else
    onFail && onFail(t('Google Ads is not available'));
  };
  const doDone = () => {
    ads.onShowing();
    //logger?.event('ads', { status: `done`, ads: adsList[adsIdx].name });
    html5Click();
    onDone && onDone();
  };

  const [supresOnShowing, setSupresOnShowing] = useState(false);
  const doShowing = () => {
    ads.onShowing();
    if (supresOnShowing) return;
    setSupresOnShowing(true);
    //logger.event('ads', { status: 'showing', ads: adsList[adsIdx].name });
    onShowing && onShowing();
  };

  //TODO: select ads
  const adsIdx = !isNativeApp()
    ? 0
    : compareVersions(nativeApp?.version, '1.12.0') < 0
    ? 2 // try Google
    : 3; // Appodeal starts from 1.12.8
  const adsList = [
    {
      name: 'Monetag',
      container: (
        <MonetagContainer
          format={format}
          onDone={doDone}
          onFail={doFail}
          onShowing={doShowing}
        />
      ),
    },
    {
      name: 'Adsense',
      container: (
        <AdsenseContainer
          format={format}
          playTime={playTime}
          onDone={doDone}
          onFail={doFail}
          onShowing={doShowing}
        />
      ),
    },
    {
      name: 'AdMob',
      container: (
        <AdMobContainer
          format={format}
          onDone={doDone}
          onFail={doFail}
          onShowing={doShowing}
        />
      ),
    },
    {
      name: 'Appodeal',
      container: (
        <AppodealContainer
          format={format}
          onDone={doDone}
          onFail={doFail}
          onShowing={doShowing}
        />
      ),
    },
  ];
  return <>{adsList[adsIdx].container}</>;
}
